import React, { useCallback, useEffect, useState } from 'react'
import { TranslationContext } from './TranslationContext'
import { Loading } from '../components/Loading/Loading'
import { localeToStringsJson } from '../assets/locale'
import { supportedIILocales } from '../helpers'

interface TranslationProviderProps {
  children: React.ReactNode
  language: string
  country: string
  componentName?: string
  isHpx?: boolean
}

export const TranslationProvider: React.FC<TranslationProviderProps> = ({
  children,
  language,
  country,
  componentName,
  isHpx
}) => {
  const [currentTranslation, setCurrentTranslation] = useState<Record<
    string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  > | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedLocale, setSelectedLocale] = useState(
    `${language.toLowerCase()}_${country.toUpperCase()}`
  )
  const [supportedLocales, setSupportedLocales] = useState<
    Record<string, unknown>
  >({})

  useEffect(() => {
    const localesObject: Record<string, unknown> = {}
    supportedIILocales.forEach((locale) => {
      const [countryKey, language] = locale.split('/')
      const country = countryKey.toUpperCase()
      if (country === 'UK') return
      const localeCode = `${language}_${country}`
      if (localeToStringsJson[localeCode]) {
        localesObject[localeCode] = localeToStringsJson[localeCode]
      }
    })
    setSupportedLocales(localesObject)
  }, [])

  const changeTranslation = useCallback(async (locale: string) => {
    let translation = localeToStringsJson[locale]
    let isDefaultTranslationLoaded = false
    setSelectedLocale(locale)

    if (!translation) {
      console.log(`Failed to load translation: ${locale}`)
      console.log('\nSetting default language to en_US')
      setSelectedLocale('en_US')
      translation = localeToStringsJson['en_US']
      isDefaultTranslationLoaded = true
    }

    if (!translation && isDefaultTranslationLoaded) {
      console.error('Failed to load default translation')
      return
    }

    setCurrentTranslation(translation)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    const locale = `${language.toLowerCase()}_${country.toUpperCase()}`
    changeTranslation(locale)
  }, [changeTranslation, country, language])

  const getText = useCallback(
    (translationPath: string, options?: Record<string, unknown>): string => {
      if (!currentTranslation) {
        return null
      }

      const keys = translationPath.split('.')
      let translation = currentTranslation
      for (const key of keys) {
        if (translation[key]) {
          translation = translation[key]
        } else {
          return `${selectedLocale}.${translationPath}`
        }
      }
      if (!translation && String(translation) !== '') {
        return `${selectedLocale}.${translationPath}`
      }
      if (translation.includes('{{')) {
        for (const key in options) {
          const regex = new RegExp(`{{${key}}}`, 'g')
          translation = translation.replace(regex, options[key] as string)
        }
      }
      return translation as unknown as string
    },
    [currentTranslation, selectedLocale]
  )

  if (
    (isLoading || Object.keys(supportedLocales).length === 0) &&
    componentName !== 'GapHandler'
  ) {
    return <Loading isHpx={isHpx} />
  }

  return (
    <TranslationContext.Provider
      value={{ getText, changeTranslation, supportedLocales }}
    >
      {children}
    </TranslationContext.Provider>
  )
}
