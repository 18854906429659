import React, { useState } from 'react'
import { TranslationProvider } from '../../contexts/TranslationProvider'
import { CountrySelectorButton } from './CountrySelectorButton/CountrySelectorButton'
import { CountrySelectorModal } from './CountrySelectorModal/CountrySelectorModal'
import { GapHandlerModal } from '../GapHandler/GapHandlerModal/GapHandlerModal'

export interface CountrySelectorProps {
  /* showCountrySelector: Opens or close the Country Selector modal */
  showCountrySelector: boolean

  /* setShowCountrySelector: Callback to closes the Country Selector modal */
  setShowCountrySelector: (showCountrySelector: boolean) => void

  /* showFlagButton: Show or not the button with the country flag and code
   * If the value is false then the showCountrySelector
   * and setShowCountrySelector properties should be passed
   * */
  showFlagButton?: boolean

  /* accountLocale: The locale registered on the account
   * Should be like 'en_US', 'fr_CA'
   * */
  accountLocale: string

  /* onSave: Callback to save the changes made. Will send as a parameter
   * the locale string like 'en_US', 'en_CA'
   * */
  onSave: (locale: string) => void

  /* isCountryChangeAllowed: Allow the user to change the country
   * If the value is false and the country is changed,
   * when the save button is clicked the Gap Handler modal will be shown.
   * */
  isCountryChangeAllowed?: boolean

  /* If the isCountryChangeAllowed is FALSE, then provide the following props
   * Default value: false
   * */

  /* onCreateNewAccount: Function to redirect to create a new account
   * Will be sent the locale code as parameter.
   * i.e. 'en_US', 'fr_CA'
   * */
  onCreateNewAccount?: (locale: string) => void

  /* onContinueEnroll: Function to continue the enrollment */
  onContinueEnrollment?: () => void

  /* analyticsCallback: Function to handle analytics events */
  analyticsCallback?: (
    linkId: string,
    linkPlacement: string,
    event?: string
  ) => void

  /* isHpx: Flag to indicate if the Country Selector should use HPX theme */
  isHpx?: boolean
}

export const CountrySelector = (props: CountrySelectorProps) => {
  const [showGapHandlerModal, setShowGapHandlerModal] = useState(false)
  const [selectedLocale, setSelectedLocale] = useState(props.accountLocale)
  const [language, country] = props.accountLocale.split('_')

  const onSave = (locale: string) => {
    const selectedCountry = locale.split('_')[1]
    if (!props.isCountryChangeAllowed && selectedCountry !== country) {
      setSelectedLocale(locale)
      setShowGapHandlerModal(true)
    } else {
      props.onSave(locale)
    }

    props.setShowCountrySelector(false)
  }

  const onContinueEnrollment = () => {
    setShowGapHandlerModal(false)
    setSelectedLocale(props.accountLocale)
    props.onContinueEnrollment()
  }

  const countrySelectorProps = {
    showCountrySelector: props.showCountrySelector,
    setShowCountrySelector: props.setShowCountrySelector,
    onSave,
    accountLocale: props.accountLocale,
    isCountryChangeAllowed: props.isCountryChangeAllowed,
    analyticsCallback: props.analyticsCallback,
    isHpx: props.isHpx
  }

  const gapHandlerProps = {
    showGapHandlerModal,
    onContinueEnrollment,
    onCreateNewAccount: props.onCreateNewAccount,
    selectedLocale,
    accountLocale: props.accountLocale,
    isUnsupportedCountry: false,
    analyticsCallback: props.analyticsCallback
  }

  const countrySelectorButtonProps = {
    accountLocale: props.accountLocale,
    setShowCountrySelector: props.setShowCountrySelector
  }

  return (
    <div data-testid="country-selector-component">
      <TranslationProvider
        language={language}
        country={country}
        isHpx={props.isHpx}
      >
        {props.showFlagButton && (
          <CountrySelectorButton {...countrySelectorButtonProps} />
        )}
        <CountrySelectorModal {...countrySelectorProps} />
        <GapHandlerModal {...gapHandlerProps} />
      </TranslationProvider>
    </div>
  )
}

CountrySelector.defaultProps = {
  showCountrySelector: false,
  setShowCountrySelector: () => null,
  showFlagButton: false,
  accountLocale: 'en_US',
  onSave: () => null,
  isCountryChangeAllowed: false,
  onCreateNewAccount: () => null,
  onContinueEnrollment: () => null,
  analyticsCallback: () => null,
  isHpx: false
}
