import React from 'react'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import Lottie from 'lottie-react'
import lottieSpinner from './lottieSpinner.json'
import * as Styled from './styles'

export const Loading = ({ isHpx }: { isHpx: boolean }) => {
  return isHpx ? (
    <Styled.LottieSpinner data-testid="lottie-spinner">
      <Lottie animationData={lottieSpinner} loop autoplay />
    </Styled.LottieSpinner>
  ) : (
    <ProgressIndicator data-testid="loading-progress-indicator" />
  )
}
