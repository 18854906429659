import React, { useState } from 'react'
import * as Flags from '@veneer/flags/dist'
import * as Styled from './styles'
import { useWindowResize } from '../../../hooks'

interface CountrySelectorButtonProps {
  accountLocale: string
  setShowCountrySelector: (value: boolean) => void
}

export const CountrySelectorButton = ({
  accountLocale,
  setShowCountrySelector
}: CountrySelectorButtonProps) => {
  const [shouldCompact, setShouldCompact] = useState(false)
  const handleResize = () => setShouldCompact(window.innerWidth <= 575)

  useWindowResize(handleResize)

  const countryCode = accountLocale.split('_')[1].toUpperCase()
  const languageCode = accountLocale.split('_')[0].toUpperCase()

  const countryFlag = () => {
    const Flag = Flags[`Flag${countryCode}`]

    return <Flag size={24} />
  }

  const onOpen = () => setShowCountrySelector(true)

  return (
    <>
      <Styled.CountryButton
        data-testid="country-selector-button"
        appearance={'ghost'}
        onClick={onOpen}
        $shouldCompact={shouldCompact}
        data-analyticsplacement="ii_header"
        data-analyticsid="change-country-locale-settings"
      >
        <Styled.FlagContainer $shouldCompact={shouldCompact}>
          {countryFlag()}
        </Styled.FlagContainer>
        <Styled.CountryCode>{languageCode}</Styled.CountryCode>
      </Styled.CountryButton>
    </>
  )
}
